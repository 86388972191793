import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, map } from 'rxjs';

import { API_URL_PREFIX } from '../utils/injection-token';
import { iConfig, iConfigResponse } from 'src/app/core/models/config.model';
import { iContact } from 'src/app/core/models/contact.model';
import { iCounter } from 'src/app/core/models/counter.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private urlAPI: string;

  constructor(
    private http: HttpClient,
    @Inject(API_URL_PREFIX) private readonly apiUrlPrefix: string
  ) {
    this.urlAPI = this.apiUrlPrefix;
  }

  public postConfig(configObject: iConfig | any): Observable<iConfigResponse> {
    return this.http.post<iConfigResponse>(this.urlAPI + '/add', configObject);
  }

  public joinSession(sessionID: string): Observable<iConfigResponse> {
    return this.http.get<iConfigResponse>(this.urlAPI + '/join/' + sessionID);
  }

  public postFeedback(contactObject: iContact): Observable<any> {
    return this.http.post(this.urlAPI + '/contact', contactObject);
  }

  public getOneDimensionalCounter(): Observable<number> {
    return this.http
      .get<iCounter>(this.urlAPI + '/counter/ONE_DIMENSIONAL_GAME')
      .pipe(map((counter) => counter.amount));
  }

  public getMultiDimensionalCounter(): Observable<number> {
    return this.http
      .get<iCounter>(this.urlAPI + '/counter/MULTI_DIMENSIONAL_GAME')
      .pipe(map((counter) => counter.amount));
  }
  public getVotingRoundCounter(): Observable<number> {
    return this.http.get<iCounter>(this.urlAPI + '/counter/VOTING_ROUND').pipe(map((counter) => counter.amount));
  }
}
