<footer class="footer flex gap-1 justify-between items-center w-full px-4">
  <div class="center-caption font-medium hidden sm:flex sm:justify-center sm:items-center">
    <span>Made with</span>
    <img [src]="'../../../assets/image-svg/btn-heart.svg'" alt="heart" class="center-caption--heart" />
    <span>by</span>
    <a class="link cursor-pointer" href="https://www.britenet.eu/" target="_blank">&nbsp;Britenet.eu</a>
  </div>

  <div class="center-caption font-medium flex justify-center items-center sm:hidden">
    <span>with</span>
    <img [src]="'../../../assets/image-svg/btn-heart.svg'" alt="heart" class="center-caption--heart" />
    <span>by</span>
    <a class="link cursor-pointer" href="https://www.britenet.eu/" target="_blank">&nbsp;Britenet</a>
  </div>

  <div class="release-notes flex flex-nowrap font-medium">
    <p class="sm:hidden">Ver. {{ version }}</p>
    <p class="hidden sm:inline">Version {{ version }}</p>
    <a class="version cursor-pointer" (click)="showReleaseNotes()">What's new?</a>
  </div>
</footer>
