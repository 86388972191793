<div class="dialog-title flex items-center justify-between p-6">
  <h1 class="w-fit" mat-dialog-title>{{ title }}</h1>
  <mat-icon mat-dialog-close>close</mat-icon>
</div>
<div mat-dialog-content class="dialog-content py-8 px-7">
  <ng-content></ng-content>
</div>

<div
  mat-dialog-actions
  class="dialog-actions flex flex-col-reverse sm:flex-row justify-end items-center gap-4 mt-8"
  [align]="'end'">
  <ng-content select="[actions]"></ng-content>
</div>
