import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReleaseNotesComponent } from '../release-notes/release-notes.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class FooterComponent {
  public version: string = '2.2.0';
  constructor(private readonly dialog: MatDialog) {}

  showReleaseNotes(): void {
    this.dialog.open(ReleaseNotesComponent, { autoFocus: false });
  }
}
