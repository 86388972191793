import { Routes } from '@angular/router';

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('./modules/landing/landing-page.component').then((c) => c.LandingPageComponent)
  },
  {
    path: 'configuration',
    loadComponent: () => import('./modules/config/config.component').then((c) => c.ConfigComponent)
  },
  {
    path: 'play/:sessionid',
    loadComponent: () => import('./game/game.component').then((c) => c.GameComponent)
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  }
];
