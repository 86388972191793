<app-dialog title="Release notes">
  <h3>Release 2.2.0</h3>
  <p class="release-date">Date: 8 May 2024</p>
  <p>System related:</p>
  <ul>
    <li>Brand new UI & UX design for mobile devices</li>
  </ul>
  <p class="release-chapter">Game related changes:</p>
  <ul>
    <!-- <li>Edit my vote - new button for players</li> -->
    <li>UI and UX improvements</li>
    <li>Minor bug fixing</li>
  </ul>
  <p class="release-chapter">Next in line:</p>
  <ul>
    <li>New Timer feature</li>
    <li>Voting title component - more smooth management for multi titles</li>
  </ul>

  <h3>Release 2.1.1</h3>
  <p class="release-date">Date: 10 April 2024</p>
  <p>System related:</p>
  <ul>
    <li>NA</li>
  </ul>
  <p class="release-chapter">Game related changes:</p>
  <ul>
    <li>Bug fixing</li>
    <!-- <li>Edit my vote - new button for players</li> -->
  </ul>
  <p class="release-chapter">Next in line:</p>
  <ul>
    <li>New Timer</li>
    <li>Voting title component - more smooth management for multi titles</li>
  </ul>
  <h3>Release 2.1.0</h3>
  <p class="release-date">Date: 14 March 2024</p>
  <p>System related:</p>
  <ul>
    <li>Dark mode - color improvements</li>
  </ul>
  <p class="release-chapter">Game related changes:</p>
  <ul>
    <li>Summary charts - new design</li>
    <!-- <li>Edit my vote - new button for players</li> -->
  </ul>
  <p class="release-chapter">Next in line:</p>
  <ul>
    <li>New Timer</li>
    <li>Voting title component - more smooth management for multi titles</li>
  </ul>
  <h3>Release 2.0.0</h3>
  <p class="release-date">Date: 18 January 2024</p>
  <p>System related:</p>
  <ul>
    <li>New UI & UX design</li>
    <li>Table name setting</li>
  </ul>
  <p class="release-chapter">Game related:</p>
  <ul>
    <li>Timer - temporary unavailable</li>
    <li>Summary charts - temporary unavailable</li>
  </ul>
  <p class="release-chapter">Next in line:</p>
  <ul>
    <li>New Timer</li>
    <li>Summary charts - new design</li>
    <li>Dark mode - color improvements</li>
    <li>Voting title component - more smooth management for multi titles</li>
  </ul>

  <h3>Release 1.1.1</h3>
  <p class="release-date">Date: 11 November 2023</p>
  <p>System related:</p>
  <ul>
    <li>Bug fixes</li>
  </ul>

  <h3>Release 1.1.0</h3>
  <p class="release-date">Date: 2 November 2023</p>
  <p>System related:</p>
  <ul>
    <li>Added Dark mode compatibility</li>
    <li>Editing avatars</li>
    <li>Release notes</li>
  </ul>
  <p class="release-chapter">Game related:</p>
  <ul>
    <li>Adding title for the estimation topic</li>
    <li>Removing players from the table</li>
    <li>Downloading table history</li>
    <li>Adding Timer</li>
  </ul>

  <h3>Release 1.0.2</h3>
  <p class="release-date">Date: 17 October 2023</p>
  <p>System related:</p>
  <ul>
    <li>Bug fixes</li>
  </ul>

  <h3>Release 1.0.1</h3>
  <p class="release-date">Date: 25 September 2023</p>
  <p>System related:</p>
  <ul>
    <li>Extending characters limit in Users and Dimensions fields</li>
    <li>Bug fixes</li>
  </ul>

  <h3>Release 1.0.0</h3>
  <p class="release-date">Date: 12 September 2023</p>
  <p>First release</p>

  <ng-container actions>
    <button mat-button class="stroke-button" mat-dialog-close>Close</button>
  </ng-container>
</app-dialog>
