import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogClose, MatDialogModule } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-release-notes-modal',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DialogComponent, MatButtonModule, MatDialogModule, MatDialogClose]
})
export class ReleaseNotesComponent {}
