import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ThemeMode } from 'src/app/core/enums/theme-mode';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  themeMode$: BehaviorSubject<ThemeMode>;

  constructor() {
    this.themeMode$ = new BehaviorSubject(this.getMode());
  }

  getMode(): ThemeMode {
    const mode = localStorage.getItem('mode') as ThemeMode | null;
    return mode || ThemeMode.LIGHT;
  }

  saveNewMode(mode: ThemeMode): void {
    localStorage.setItem('mode', mode);
    this.themeMode$.next(mode);
  }
}
