<nav class="w-full min-w-72">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <a href="" class="flex items-center space-x-3 rtl:space-x-reverse">
      <img [src]="hideLogoName ? mobileLogoUrl :logoUrl" class="h-8" alt="Britesizing Logo" />
    </a>
    <section class="top-bar--action-hamburger sm:hidden">
      <button mat-icon-button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="goToCustomConfiguration()">
          <mat-icon>add</mat-icon>
          Start new game</button
        ><button mat-menu-item (click)="switchThemeMode()">
          <mat-icon>wb_sunny</mat-icon>
          {{isDarkMode ? 'Dark': 'Light'}} Mode</button
        ><button mat-menu-item (click)="openContactForm()">
          <mat-icon>chat</mat-icon>
          Rate the app
        </button>
      </mat-menu>
    </section>

    <section class="top-bar--action-section hidden w-full sm:block sm:w-auto">
      <mat-slide-toggle [formControl]="darkModeControl">Dark mode</mat-slide-toggle>
      <button mat-button class="button-cta flat-button" (click)="goToCustomConfiguration()">
        Start new game
      </button>
    </section>
  </div>
</nav>
