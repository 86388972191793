<app-dialog title="Contact Form">
  <form class="content" [formGroup]="contactForm">
    <div class="rating mb-4">
      <p class="rate-title">Rate our app</p>
      <p class="rate-subtitle">You can just rate our app and send a form. Thanks in advance!</p>
      <mat-radio-group
        aria-label="application rating mb-4"
        color="primary"
        formControlName="note"
        class="mt-2 flex flex-col">
        @for(option of options; track option.value){
        <mat-radio-button [value]="option.value">{{option.label}}</mat-radio-button>
        }
      </mat-radio-group>
    </div>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Comment</mat-label>
      <textarea
        matInput
        formControlName="description"
        placeholder="This is the place for your comment, e.g. suggestion of improvement / reporting a problem or bug"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>E-mail</mat-label>
      <input matInput formControlName="email" />
      <mat-error *ngIf="contactForm.get('email')?.errors"> Email is not valid </mat-error>
      <mat-hint>If you want you can leave a contact to you. It’s optional.</mat-hint>
    </mat-form-field>
  </form>

  <ng-container actions>
    <button type="button" mat-button class="stroke-button" mat-dialog-close>Cancel</button>
    <button type="button" mat-button class="flat-button" (click)="sendData()">Send</button>
  </ng-container>
</app-dialog>
